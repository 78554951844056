var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { display: "inline-block" },
      style: {
        width: _vm.tag === "b-dropdown-item" ? "100%" : "",
      },
    },
    [
      _vm.isDisabled && !_vm.inProdMode()
        ? _c(
            "span",
            [
              _c("subscription-dialog", {
                attrs: {
                  "variant-btn": _vm.variantBtn,
                  tag: _vm.tag,
                  icon: _vm.icon,
                  "btn-label": _vm.btnLabel,
                },
              }),
            ],
            1
          )
        : _c(
            "span",
            [
              !_vm.hideButton
                ? _c(
                    "div",
                    [
                      _vm.tag === "is-lucide"
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: _vm.nameModal,
                                  expression: "nameModal",
                                },
                              ],
                              attrs: {
                                variant: _vm.variantBtn,
                                disabled: _vm.isDisabled,
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t" +
                                  _vm._s(_vm.btnLabel) +
                                  "  \n\t\t\t\t"
                              ),
                              _c("Share", {
                                attrs: { color: "#06263E", size: 16 },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tag === "b-button"
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: _vm.nameModal,
                                  expression: "nameModal",
                                },
                              ],
                              attrs: {
                                variant: _vm.variantBtn,
                                disabled: _vm.isDisabled,
                              },
                            },
                            [
                              _c("i", {
                                class: _vm.icon,
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v("  " + _vm._s(_vm.btnLabel) + "\n\t\t\t"),
                            ]
                          )
                        : _vm._e(),
                      _vm.tag === "b-dropdown-item"
                        ? _c(
                            "b-dropdown-item",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: _vm.nameModal,
                                  expression: "nameModal",
                                },
                              ],
                              attrs: { disabled: _vm.isDisabled },
                            },
                            [
                              _c("b-icon", { attrs: { icon: "envelope" } }),
                              _vm._v("  " + _vm._s(_vm.btnLabel) + "\n\t\t\t"),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-modal",
                {
                  ref: "modal-share-subscription-solo",
                  attrs: {
                    id: _vm.nameModal,
                    title: _vm.FormMSG(236, "Confirm"),
                    "ok-variant": "primary",
                    "cancel-variant": "outline-primary",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    "hide-header-close": "",
                    "header-class": "header-modal-custom",
                    "body-class": "body-modal-custom",
                    "footer-class": "footer-modal-custom",
                    "ok-disabled": _vm.disabledSave,
                    "cancel-disabled": _vm.disabledCancel,
                    "cancel-title": _vm.FormMSG(4, "Cancel"),
                    "modal-class": "mui-animation",
                    fade: false,
                  },
                  on: {
                    ok: function ($event) {
                      $event.preventDefault()
                      return _vm.handleSubmitModal.apply(null, arguments)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-ok",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            [
                              _vm.disabledSave
                                ? _c("b-spinner", { attrs: { small: "" } })
                                : _vm._e(),
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(3, "Send")) +
                                  "\n\t\t\t\t"
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.isOpen,
                    callback: function ($$v) {
                      _vm.isOpen = $$v
                    },
                    expression: "isOpen",
                  },
                },
                [
                  _c(
                    "b-form",
                    [
                      _c("b-alert", {
                        attrs: {
                          variant: "danger",
                          show: _vm.errorMessage !== "",
                          dismissible: "",
                        },
                        domProps: { innerHTML: _vm._s(_vm.errorMessage) },
                      }),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _vm.currentTimesheet && _vm.isSendInvoice
                              ? _c("p", { staticClass: "font-400" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          64,
                                          "Please select the recipient and confirm the options to send the timesheet invoice."
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                            _vm.currentExpense && _vm.isSendInvoice
                              ? _c("p", { staticClass: "font-400" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          65,
                                          "Please select the recipient and confirm the options to send the expense invoice."
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      (_vm.currentTimesheet || _vm.currentExpense) &&
                      _vm.isSendInvoice
                        ? _c("div", { staticClass: "divider" })
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.handleOutside,
                              expression: "handleOutside",
                            },
                          ],
                          attrs: {
                            id: "fieldset-1",
                            label: _vm.titleModal,
                            "label-for": "input-1",
                            "label-class": "font-700 mt-3",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.emailsFromCookies,
                              taggable: "",
                              multiple: true,
                              placeholder: _vm.FormMSG(556, "Address email..."),
                            },
                            on: {
                              search: _vm.handleSearch,
                              "option:created": _vm.onOptionCreated,
                              "option:deselecting": _vm.onOptionDeselect,
                              "option:deselected": _vm.onOptionDeselect,
                            },
                            model: {
                              value: _vm.form.emails,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "emails", $$v)
                              },
                              expression: "form.emails",
                            },
                          }),
                          _vm.error.inputEmail
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(7, "Enter a address valid")
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c("b-col", { attrs: { sm: "6" } }, [
                            _c(
                              "div",
                              { staticStyle: { "line-height": "22px" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      id: "checkbox-1",
                                      name: "checkbox-1",
                                      value: true,
                                      "unchecked-value": false,
                                    },
                                    model: {
                                      value: _vm.form.save_address,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "save_address", $$v)
                                      },
                                      expression: "form.save_address",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(6, "Save this address")
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm.showCheckMemoDeal && _vm.$screen.width > 567
                            ? _c(
                                "b-col",
                                { attrs: { sm: "6" } },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        id: "checkbox-sendMemoDeal",
                                        name: "checkbox-sendMemoDeal",
                                        value: true,
                                        "unchecked-value": false,
                                      },
                                      model: {
                                        value: _vm.form.sendMemoDeal,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "sendMemoDeal",
                                            $$v
                                          )
                                        },
                                        expression: "form.sendMemoDeal",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(42, "Send memo deal")
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.sendACopy
                        ? _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c("b-col", { attrs: { sm: "8" } }, [
                                _c(
                                  "div",
                                  { staticStyle: { "line-height": "22px" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          id: "checkbox-2",
                                          name: "checkbox-2",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        model: {
                                          value: _vm.form.sendMeACopy,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "sendMeACopy",
                                              $$v
                                            )
                                          },
                                          expression: "form.sendMeACopy",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(10, "Send me a copy")
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c("b-col", { attrs: { sm: "8" } }, [
                            _c(
                              "div",
                              { staticStyle: { "line-height": "22px" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      id: "printPdf",
                                      name: "checkbox-2",
                                      value: true,
                                      "unchecked-value": false,
                                    },
                                    model: {
                                      value: _vm.form.printPdf,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "printPdf", $$v)
                                      },
                                      expression: "form.printPdf",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(28, "Send in PDF format")
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      (_vm.currentTimesheet || _vm.currentExpense) &&
                      _vm.isSendInvoice
                        ? _c(
                            "div",
                            [
                              _c("div", { staticClass: "divider" }),
                              _c("p", { staticClass: "font-700 mt-3" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(67, "Invoicing options:")
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]),
                              _c(
                                "b-row",
                                { staticClass: "mb-2 mt-4" },
                                [
                                  _c("b-col", { attrs: { cols: "6" } }, [
                                    _c("p", { staticClass: "font-400" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(68, "Invoice number:")
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: "########",
                                                expression: "'########'",
                                              },
                                            ],
                                            staticClass: "custom-form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder: "0",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.handleInputNumericField(
                                                  $event,
                                                  "invoiceNumber"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.invoiceNumber,
                                              callback: function ($$v) {
                                                _vm.invoiceNumber = $$v
                                              },
                                              expression: "invoiceNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.currentTimesheet
                                ? _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c("b-col", { attrs: { cols: "6" } }, [
                                        _c("p", { staticClass: "font-400" }, [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(69, "VAT:")) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "########",
                                                    expression: "'########'",
                                                  },
                                                ],
                                                staticClass:
                                                  "custom-form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "0",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.handleInputNumericField(
                                                      $event,
                                                      "vat"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.vat,
                                                  callback: function ($$v) {
                                                    _vm.vat = $$v
                                                  },
                                                  expression: "vat",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "custom-input-group-text",
                                                    },
                                                    [
                                                      _c("percent", {
                                                        attrs: { size: 16 },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-row",
                                { staticClass: "mb-2" },
                                [
                                  _c("b-col", { attrs: { cols: "6" } }, [
                                    _c("p", { staticClass: "font-400" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(70, "Discount:")) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: "########",
                                                expression: "'########'",
                                              },
                                            ],
                                            staticClass: "custom-form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder: "0",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.handleInputNumericField(
                                                  $event,
                                                  "discount"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.discount,
                                              callback: function ($$v) {
                                                _vm.discount = $$v
                                              },
                                              expression: "discount",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-input-group-text",
                                                {
                                                  staticClass:
                                                    "custom-input-group-text",
                                                },
                                                [
                                                  _c("percent", {
                                                    attrs: { size: 16 },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }